.has-table-tools {
    @apply  justify-end;
}
.dt-container {

    .dt-layout-row:first-child {

        @apply -mt-10 mb-5;

        @apply flex justify-normal;

        .dt-search {

            @apply relative;

            &:after {
                @apply absolute right-2 top-2.5;
                @apply text-xs;
                font-family: 'Font Awesome 6 Pro';
                content: "\e0ad";

                @apply text-ghost-white;

                html.dark & {
                    @apply text-ghost-white/10;
                }

            }

            input {

                @apply bg-transparent;
                @apply border border-ghost-white text-sm p-2;
                @apply outline-none focus:outline focus:outline-1;
                @apply hover:border-sapphire active:border-sapphire;

                html.dark & {
                    @apply border-ghost-white/10;
                    @apply hover:border-sapphire active:border-sapphire active:outline-none;
                }
            }

        }

        .dt-end {

            @apply text-right;

        }

    }

}

.hold-table {
    //@apply overflow-x-auto;
}

.DataTable {

    /* Default state for non-ordered column */
    th::after {
        @apply inline-block align-middle ml-2 opacity-30;
        content: '\f0dc'; /* FontAwesome icon for 'sort' (fa-sort) */
        font-family: 'Font Awesome 6 Pro';
        font-weight: 900;
    }

    /* Ascending order */
    th.dt-ordering-asc::after {
        @apply opacity-100;
        content: '\f0de'; /* FontAwesome icon for 'sort-up' (fa-sort-up) */
    }

    /* Descending order */
    th.dt-ordering-desc::after {
        @apply opacity-100;
        content: '\f0dd'; /* FontAwesome icon for 'sort-down' (fa-sort-down) */
    }

    th[data-orderable="false"]::after {
        content: '';
    }

}

table {

    //@apply table-auto;
    @apply w-full text-left text-sm;

    th {

        @apply uppercase text-spanish-gray tracking-widest text-[.625rem] lg:text-xs;
        @apply p-1 md:p-2;
        @apply align-bottom;

    }

    .has-tools {
        @apply justify-end;
    }

    tr {
        @apply border-ghost-white border-t;

        html.dark & {
            @apply border-ghost-white/10;
        }

    }

    td {

        @apply p-1 md:p-2 align-top;

    }

    thead tr  {
        @apply border-0;
    }

    tbody tr {

        &:nth-child(odd) {

            //@apply bg-ghost-white;

        }

        // Hide the show grouped orders button for all rows
        [js-toggle-groupedorders] {
            @apply hidden;
        }

        &[data-parent-order-id] {

            // unless the source row has 'children'
            &.has-grouped-orders {

                [js-toggle-groupedorders] {
                    @apply block;
                }

            }

        }

        // This is a child row and is 'hidden'
        &[data-order-id] {

            td {
                @apply hidden h-0;
            }

            &.is-visible {

                td {
                    @apply table-cell h-full;
                }

            }

        }

    }

}