.is-nav-toggle {

    @apply flex lg:hidden;

    @apply border-none bg-none cursor-pointer;

    @apply relative w-4 h-4;

    span {

        @apply block absolute left-0 w-full transition-all h-0.5;

        @apply bg-white;

        transform: rotate(0deg);

        &:nth-child(1) {
            @apply top-0;
        }

        &:nth-child(2),
        &:nth-child(3) {
            top: 6px;
        }

        &:nth-child(4) {
            top: 12px;
        }

    }

    &.active span:nth-child(1) {
        top: 8px;
        width: 0%;
        left: 50%;
    }

    &.active span:nth-child(2) {
        transform: rotate(45deg);
    }

    &.active span:nth-child(3) {
        transform: rotate(-45deg);
    }

    &.active span:nth-child(4) {
        top: 8px;
        width: 0%;
        left: 50%;
    }


}