#darkmode {
    appearance: none;
    outline: none;
    box-shadow: none;
    cursor: pointer;
    overflow: hidden;
    transition: 0.15s ease-in-out;

    width: 40px; /* Updated width */
    height: 20px; /* Updated height */
    border-radius: 10px; /* Adjusted for smaller size */

    @apply absolute top-10 right-4 bg-ghost-white;

    &:before {
        content: '';
        position: absolute;
        top: 1px; /* Adjust for new size */
        left: 1px; /* Adjust for new size */
        border-radius: inherit;
        width: 18px; /* Adjusted for new size */
        height: 18px; /* Adjusted for new size */
        transition: inherit;

        background: #fff;
    }

    &:after {
        content: '';
        position: absolute;
        top: -2px; /* Adjust for new size */
        right: calc(100% + 18px); /* Adjust for new size */
        border-radius: inherit;
        width: 18px; /* Adjusted for new size */
        height: 18px; /* Adjusted for new size */
        transition: inherit;

        background: #fff;
    }

    &:checked {
        @apply bg-sapphire;
    }
}

#darkmode:checked::before {
    left: calc(100% - 19px); /* Adjusted for new size */
    background: #fff;
}

#darkmode:checked::after {
    right: 9px; /* Adjust for new size */
    @apply bg-sapphire;
}