[js-tooltip] {
    @apply  cursor-pointer;
    &.active {
        i {
            @apply transition-transform ease-in-out;
            animation: pulse 1s infinite;
        }

    }
}
.js-tooltip {
    @apply shadow-lg absolute hidden;
    @apply bg-oxford-blue text-xs text-white p-2 rounded;
    @apply border border-ghost-white;
    html.dark & {
        @apply border-ghost-white/10;
    }

    &.active {
        @apply block;
    }
}

.js-tooltip-detail {

    @apply shadow-lg absolute hidden;
    @apply bg-white p-4 md:p-8 rounded;
    @apply border border-ghost-white;
    html.dark & {
        @apply border-ghost-white/10;
    }

    html.dark & {
        @apply bg-oxford-blue;
    }
    &.active {
        @apply block;
    }

    p {
        @apply text-sm pt-1 pb-1;
        span {
            @apply font-medium text-xs uppercase tracking-widest text-spanish-gray block;
        }
    }

}

.js-tooltip-detail .close {
    @apply cursor-pointer text-sm text-gray-500;
}