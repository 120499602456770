input[type="password"],
input[type="text"],
select , textarea {

    @apply outline-none focus:outline focus:outline-1;

    @apply p-2 w-full;
    @apply rounded-sm border;
    @apply border-ghost-white;
    @apply hover:border-sapphire active:border-sapphire active:outline-none;

    html.dark & {

        @apply border-ghost-white/10;
        @apply hover:border-sapphire active:border-sapphire active:outline-none;

    }

    @apply bg-white;

    html.dark & {
        @apply bg-oxford-blue;
    }

}

form {

    /**
     * Use this on a dark background
     */
    &.has-bg {

        label {
            @apply text-white;
        }

        .f {

            input , select , textarea {

                @apply border-white/20 bg-transparent text-white;
                @apply hover:border-white active:border-white;

            }

        }

    }

}

fieldset {
    @apply w-full p-4;
    @apply border border-ghost-white rounded-xl mb-8;

    html.dark & {
        @apply border-ghost-white/10;
    }
}

legend {
    @apply mb-4 text-sm font-bold text-sapphire;
}

.formcheck ,
.formradio {
    @apply flex;
    input {
        @apply mr-2;
    }
}

.formgroup {

    @apply block mb-4;

    &:last-child {
        @apply mb-0;
    }

    label {
        @apply block text-sm pt-1 pb-1;
    }

    .f {

        &.group {

            @apply flex gap-0 flex-grow mb-2;

            input {

            }

            .btn {

                @apply border-l-0;
                @apply border-ghost-white bg-transparent rounded-none text-black max-h-full;
                @apply text-gray-400; // todo

                html.dark & {
                    @apply border-ghost-white/10;
                }

                &[disabled] ,
                &:disabled {
                    @apply opacity-10;
                }

            }

            &:first-child {

                .js-btn-remove {

                    display: none;

                }
            }

            &:last-child {
                @apply mb-0;
            }

        }

    }

}