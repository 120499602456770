.tabs {

    @apply relative overflow-hidden;

    nav {

        @apply z-10 relative;
        @apply pl-8 pr-8;
        @apply border-b border-ghost-white dark:border-ghost-white/10;

        ul {

            @apply mb-0 pl-0 list-none;
            @apply flex text-xs uppercase tracking-widest flex-wrap md:flex-nowrap;

            li {

                @apply relative;

                a {

                    @apply transition block pt-4 pb-4 pr-8 lg:pr-16;
                    @apply font-medium;
                }

                &.active {

                    &:after {
                    }

                    a {
                        @apply font-bold;
                    }

                }
            }

        }

    }

    div.tab {

        @apply absolute opacity-0 transition-opacity w-full pt-8;

        &.active {

            @apply relative opacity-100;

        }

    }

}