.hu-ve-vg-filter {

    & > div {

        @apply flex;
        @apply items-center;
        @apply lg:min-w-32 xl:min-w-40;

        & > select {
            @apply h-10;

            @apply text-xs xl:text-sm;
        }

        & > button {

            @apply h-10;
            @apply p-2 border border-l-0 border-ghost-white text-sapphire text-xs rounded rounded-l-none;
            @apply hover:bg-sapphire hover:text-white;

            html.dark & {
                @apply border-ghost-white/10;
            }

        }

    }

    &.is-disabled {

        @apply opacity-20;

        select {

        }

    }
}