$fa-font-path: "../webfonts";
@import "../fonts/fontawesome/scss/fontawesome";
@import "../fonts/fontawesome/scss/light";
@import "../fonts/fontawesome/scss/regular";
@import "../fonts/fontawesome/scss/solid";
@import "../fonts/Frutiger/stylesheet.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@import "cards.scss";
@import "darkmode.scss";
@import "error.scss";
@import "forms.scss";
@import "hu-ve-vg-filter.scss";
@import "is-nav-toggle.scss";
@import "js-tooltip.scss";
@import "quickfilter.scss";
@import "modals.scss";
@import "tables.scss";
@import "tabs.scss";

.tw-pt-8 {
    @apply pt-8; // todo for bottom section on dashboard
}

// move to component
@keyframes pulse {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    50% {
        transform: scale(1.25);

    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}







body {

    @apply w-full relative overflow-x-hidden;

}


.all {

    @apply lg:flex flex-grow;
    @apply min-h-screen;

    & > aside {

        body.nav-active & {
            @apply block absolute;
        }

        section {
            @apply pl-8 pr-8;
        }

        .flashes {
            @apply pl-8 pr-8;

            body.log-in & {
                @apply mb-8;
                @apply text-white text-sm;
            }
        }

        & > div.brand {

            @apply pt-4 pb-8;
            @apply pl-3 pr-3;
            @apply relative;

            & > strong.logo {

                @apply block w-20 h-20;

                g#B {

                    path ,
                    polyline {

                        @apply fill-sapphire;

                        html.dark & ,
                        body.log-in & {
                            @apply fill-white;
                        }

                    }

                }
            }

        }

        nav.primary {

            @apply text-sm;

            a {

                &:hover {
                    @apply text-sapphire;

                    html.dark & {
                        @apply text-white;
                    }

                }
            }

            & > ul {

                & > li {

                    @apply block pt-2 pb-2;
                    @apply ml-4 mr-4;

                    &:hover {

                        @apply bg-ghost-white rounded-lg;

                        html.dark & {
                            @apply bg-sapphire;
                        }

                    }

                    & > span ,
                    & > a {

                        @apply block pt-2 pb-2;
                        @apply pl-4 pr-4;

                    }

                    &.has-menu-items {

                        &:hover {
                            & > span {
                                @apply text-gray-400;
                            }
                        }

                        @apply relative;

                        &:after {
                            @apply absolute right-4 top-4;
                            @apply text-xs;
                            font-family: 'Font Awesome 6 Pro';
                            content: "\f078";
                        }

                    }

                    & > ul {

                        @apply hidden;

                        & > li {

                            & > a {

                                @apply block pt-2 pb-2;
                                @apply pl-4 pr-4;

                            }

                        }

                    }

                }

            }

        }

    }

    & > main {

        @apply lg:flex-grow;
        @apply pb-16;
        @apply pt-16 lg:pt-0;

        .flashes {

            @apply pl-8 pr-8;
            @apply pt-8 pb-8;
            @apply border-b;
            @apply border-ghost-white dark:border-ghost-white/10;
            @apply relative;

            .flash {
                @apply text-sm;
            }
        }

        & > header {

            @apply p-4 md:p-8;

        }

        section {

            @apply p-4 pb-8 md:p-8 md:pb-16;

            a.toggable {
                @apply hidden;
            }

            &.is-toggable-section {

                @apply h-8;
                @apply relative overflow-hidden;


                .hold-table ,
                form {
                    @apply hidden;
                }

                a.toggable {

                    @apply cursor-pointer block p-4 md:p-8 w-full absolute z-10 top-0 left-0 text-center;
                    @apply text-xs uppercase tracking-widest text-sapphire;
                    @apply h-8;

                    //@apply border border-ghost-white rounded-xl;

                    html.dark & {
                      //  @apply border-ghost-white/10;
                    }

                    &:after {
                        content: '';
                        @apply block;
                        @apply absolute top-0 left-0 bottom-0 right-0 -z-10;
                        @apply bg-white bg-opacity-95;
                        html.dark & {
                            @apply bg-oxford-blue bg-opacity-95;
                        }
                    }
                }
            }

        }

    }

}

.btn {

    @apply p-2 pl-3 pr-3 flex items-center justify-center border-2 border-sapphire rounded-xl;
    @apply text-sm font-semibold text-white bg-sapphire;

    &.btn-warning {
        @apply text-white bg-red-800 border-red-800;
    }
}

.btn-icon {

    @apply p-2 flex items-center justify-center text-center text-lg min-w-8 max-h-8 rounded-xl;

    &:hover {
        @apply bg-sapphire text-white;
    }

    &[js-toggle-groupedorders] {
        @apply text-sm;
    }

}

.has-tools {

    @apply flex gap-2;

}




h1 {
    @apply font-extrabold text-sapphire;
    @apply text-3xl lg:text-5xl mb-8;
}

h6 {
    @apply font-bold text-sm mb-2 md:mb-2; // todo confirm
}

// fleetlist view
p.lv {

    @apply mb-4;

    &:last-of-type {
        @apply mb-0;
    }

    .l {
        @apply uppercase text-spanish-gray text-xs tracking-widest;
    }

    .v {

    }

}

body.warehouses {

    .hi svg {

        @apply w-8 h-8;

        path {
            @apply fill-blaze-orange;
        }

    }
}