.quickfilter {

    @apply p-4 md:p-8;
    @apply border-t md:border-t-0 md:border-l;
    @apply border-ghost-white dark:border-ghost-white/10;

    form {
        @apply flex gap-0;
    }

    input[type="text"] {
        @apply h-10;
        @apply border-r-0 rounded rounded-r-none;
        @apply text-xs xl:text-sm;
    }

    button {
        @apply h-10;
        @apply p-2 border border-sapphire text-sapphire text-xs rounded rounded-l-none;
        @apply hover:bg-sapphire hover:text-white;
    }

}

.ui-widget.ui-widget-content {
    @apply p-2 border border-ghost-white text-xs;
}

.ui-autocomplete {

    .badge {
        @apply inline-block rounded bg-sapphire p-1 uppercase font-medium text-white ml-2;
        font-size: .625rem; // todo
    }

    .badge-order {
    }

    .badge-dispatch {
    }

}