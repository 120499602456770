body.modal-active {

    &:after {

        content: "";
        @apply block fixed top-0 right-0 left-0 z-10 w-full md:inset-0 h-full;
        @apply bg-white bg-opacity-95;

        html.dark & {
            @apply bg-oxford-blue bg-opacity-95;
        }

    }



}

.modal {

    @apply hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-[calc(100%-1rem)] max-h-full;

    &.active {
        @apply flex justify-center items-center;
    }

    .modal-container {

        @apply  relative p-4 w-full max-w-2xl max-h-full;

        .modal-content {

            @apply relative rounded-lg shadow;
            @apply bg-white border-ghost-white border;

            html.dark & {
                @apply bg-oxford-blue;
            }

            header {
                @apply p-4;
                @apply font-extrabold;
            }

            .modal-body {

                @apply p-4 text-sm md:text-base;
            }

            .has-tools {
                @apply p-4;
                @apply justify-normal;
            }

        }

    }

}