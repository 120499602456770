a.card.number {

    header h6 {

        @apply relative;

        i.fa-arrow-right {
            @apply text-sm transition-all opacity-0;
            @apply absolute;
        }
    }

    &:hover {

        header h6 {

            i.fa-arrow-right {
                @apply opacity-100;
                @apply ml-3;
            }
        }

        .v {
            @apply text-sapphire;
        }

    }

}

.card {

    p.v {

        @apply transition-colors;

    }

    &.hub {

        @apply w-full p-4 md:p-8;
        @apply rounded-xl transition;

        @apply border border-ghost-white;
        html.dark & {
            @apply border-ghost-white/10;
        }

        &:hover {
            @apply border-sapphire;
            html.dark & {
                @apply border-sapphire;
            }
        }

        header {

            svg {

                path {

                    @apply fill-sapphire;

                }

            }

        }

        .card-body {

            p.v {

                @apply font-bold text-4xl;

            }

        }

    }

    &.number {

        header {

            p {
                @apply font-medium text-sm uppercase tracking-widest text-spanish-gray;
            }

        }

        .card-body {

            @apply font-bold;

            /**
             * Numeric value mostly
             */
            p.v {

                @apply text-4xl md:text-6xl;

            }

            /*
             * order(s)
             */
            p.l {

                @apply font-light text-xs text-spanish-gray;

            }

        }

        &.is-large {

            .card-body {

                p.v {

                    @apply text-8xl;

                }

            }

        }

        &.is-small {

            .card-body {

                p.v {

                    @apply text-2xl md:text-4xl;

                }

            }

        }

        &.is-x-small {

            header {

                h6 {
                    @apply text-xs;
                }

            }

            .card-body {

                p.v {

                    @apply text-lg md:text-2xl;

                }

            }

        }

    }


}